import {setGlobalLocale, setGlobalTFunction} from "./translations";
import {useTranslation} from "next-i18next";
import {useRouter} from "next/router";


export const initTranslationBrowser = (tFunction?: Function, debug = false) => {
  const router = useRouter();
  const t = tFunction || useTranslation().t
  setGlobalLocale(router.locale)
  setGlobalTFunction(t)
  return t
}