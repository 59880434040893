import { useEffect, useRef, useState } from 'react';
import { withRouter } from 'next/router';
import { setPrefix } from '../../constants/api-routes.const';
import { useTranslation } from '../../translation/translations';
import { Queue } from '../../helpers/Queue';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button/Button';

const Autosuggest = require('react-autosuggest');

const cache = {};

const SearchBox = (props: any) => {
  const { t } = props;
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [redirect, setRedirect] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState<any>('');
  const [text, setText] = useState('');
  const [skip, setSkip] = useState(0);
  const [slice, setSlice] = useState(8);
  const [loading, setLoading] = useState(false);
  const [lastSuggestions, setLastSuggestions] = useState<any[]>([]);
  const textInputRef = useRef<HTMLInputElement>(null);
  const elementRef = useRef<HTMLDivElement>(null);
  const queue = new Queue();
  let throttleId = 0;
  let counter = 0;

  useEffect(() => {
    const mutationObserver = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          handleClassChange();
        }
      }
    });

    if (textInputRef.current) {
      mutationObserver.observe(textInputRef.current, { attributes: true });
    } else {
      console.log('input was not found');
    }

    return () => {
      mutationObserver.disconnect();
    };
  }, []);

  const handleClassChange = () => {
    const container = elementRef.current?.querySelector('.react-autosuggest__container');
    if (elementRef.current) {
      if (textInputRef.current?.classList.contains('react-autosuggest__input--focused')) {
        container?.classList.add('react-autosuggest__container--focused');
      } else {
        container?.classList.remove('react-autosuggest__container--focused');
      }
    }
  };

  const escapeRegexCharacters = (str: string) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const getSuggestions = async (value: string) => {
    const escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === '') return [];
    const suggestions =
      (props.fetchSuggestions && props.fetchSuggestions(value)) || (await fetchCompanySuggestions(escapedValue));
    return suggestions;
  };

  const getSuggestionValue = (suggestion: any) => {
    if (suggestion.loading) {
      return '';
    }
    setSelectedSuggestion(suggestion);
    return suggestion.ticker;
  };

  const renderSuggestion = (suggestion: any) => {
    if (suggestion.loading) {
      return (
        <div>
          <span className="search-box-name">Loading suggestions...</span>
        </div>
      );
    }
    if (suggestion.text && suggestion.action) {
      return (
        <div
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            suggestion.action(ev);
          }}
        >
          <Button
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              suggestion.action(ev);
            }}
            {...suggestion.buttonProps}
          >
            <span className="search-box-name">{suggestion.text}</span>
          </Button>
        </div>
      );
    }
    return (
      <div>
        <span className="search-box-exchange">{suggestion.exchange} </span>
        <span className="search-box-ticker">{suggestion.ticker} </span>
        <span className="search-box-name">({suggestion.companyName})</span>
      </div>
    );
  };

  const fetchCompanySuggestions = async (text: string) => {
    if (cache[text] !== undefined) {
      return cache[text];
    }
    try {
      const res = await fetch(setPrefix('/api/data/search?expression=' + text));
      if (res.ok) {
        const result = [...(await res.json())];
        cache[text] = result;
        return result;
      } else throw new Error("Couldn't fetch, status: " + res.status);
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const onSuggestionSelected = (event: any, { suggestion }: any) => {
    event.preventDefault();
    if (suggestion.loading) {
      return;
    }
    if (suggestion.action) {
      suggestion.action();
      return;
    }
    if (props.onSelected) {
      props.onSelected(suggestion);
    } else {
      navigate(suggestion);
    }
  };

  const navigate = ({ ticker }: any) => {
    props.router.push(props.t('/stocks') + '/' + ticker);
  };

  const onChange = (event: any, { newValue, method }: any) => {
    setValue(newValue);
    setSkip(0);
    setSlice(8);
  };

  const throttleRequests = async () => {
    const id = ++throttleId;
    await new Promise((resolve) => setTimeout(resolve, 500));
    return throttleId === id;
  };

  const onSuggestionsFetchRequested = async ({ value }: any) => {
    if (text === value.toUpperCase()) {
      if (lastSuggestions && lastSuggestions.length) {
        setSuggestions(lastSuggestions);
      }
      return;
    }
    if (!(await throttleRequests())) {
      return;
    }
    counter++;
    const currentCounter = counter;
    setText(value.toUpperCase());
    setSuggestions([]);
    setLoading(true);
    const task = { term: value, job: getSuggestions(value), value: null, done: false };
    task.value = await task.job;
    task.done = true;
    if (counter === currentCounter) {
      const suggestions = task.value;
      setSuggestions(suggestions);
      setLastSuggestions(suggestions);
      setLoading(false);
    }
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getPlaceholder = () => {
    if (props.ticker) return props.t('Current ticker: ') + props.ticker;
    else return props.short ? props.t('Ticker or Name') : props.t('Enter company name or ticker...');
  };

  const submit = (event: any) => {
    event.preventDefault();
    if (suggestions.length) {
      const existingTicker = suggestions.find((s) => s.ticker === value);
      if (props.onSelected) {
        props.onSelected({ ticker: existingTicker ? existingTicker.ticker : suggestions[0].ticker });
      } else {
        navigate({ ticker: existingTicker ? existingTicker.ticker : suggestions[0].ticker });
      }
    } else {
      if (props.onSelected) {
        return;
      } else {
        navigate({ ticker: value?.toUpperCase() });
      }
    }
  };

  let _suggestions = suggestions.slice(0, slice);
  if (text && !props.noSearch && !(_suggestions.length >= 1 && _suggestions[0].ticker === text)) {
    _suggestions = [..._suggestions, { ticker: text, companyName: 'Search for this ticker' }];
  } else {
    _suggestions = suggestions.slice(skip, skip + slice);
  }
  if (suggestions.length > skip + slice) {
    _suggestions = [
      ..._suggestions,
      {
        text: 'Next...',
        action: (ev) => {
          setSkip(skip + slice);
          if (textInputRef.current) {
            textInputRef.current.scrollIntoView();
          }
        },
        buttonProps: { color: 'primary' } as ButtonProps,
      },
    ];
  }
  if (skip > 0) {
    _suggestions = [
      {
        text: 'Prev...',
        action: (ev) => {
          setSkip(skip - slice);
          if (textInputRef.current) {
            textInputRef.current.scrollIntoView();
          }
        },
        buttonProps: { color: 'primary' } as ButtonProps,
      },
      ..._suggestions,
    ];
  }

  if (loading) {
    _suggestions = [..._suggestions, { loading: true }];
  }

  return (
    <form onSubmit={submit}>
      <div ref={elementRef} className={props.icon ? 'autosuggest-with-icon' : ''}>
        {props.icon && <div className="autosuggest-icon">{props.icon}</div>}
        <div ref={textInputRef}></div>
        <Autosuggest
          suggestions={_suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={({ onBlur, onFocus, onChange, value, ...inputProps }) => {
            return (
              <input
                {...inputProps}
                type={'text'}
                value={value}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={(ev) => {
                  onChange(ev, null);
                }}
                ref={textInputRef}
              />
            );
          }}
          inputProps={{
            id: 'search',
            name: 'search',
            placeholder: getPlaceholder(),
            value,
            onChange,
          }}
        />
      </div>
    </form>
  );
};

const withTranslation = (WrappedComponent) => (props) => {
  const { t } = useTranslation();
  return <WrappedComponent {...props} t={t} />;
};

export default withRouter(withTranslation(SearchBox));
