import {initTranslationBrowser} from "./translation-browser";

export let globalTFunction = null;
export let globalTranslationProps = null;
export let globalLocale = null;

const resolvers = []

export function translatedValue(getValue) {
  return new Promise(resolve => {
    if (globalTFunction) {
      resolve(getValue(globalTFunction))
      resolvers.push({getValue,resolve})
    } else {
      resolve(getValue((v)=>v))
      resolvers.push({getValue,resolve})
    }
  })
}

export const setGlobalTFunction = (tFunction) => {
  globalTFunction = tFunction
  resolvers.forEach(({resolve,getValue}) => {resolve(getValue(globalTFunction))})
}

export const setGlobalTranslationProps = (translationProps) => {
  globalTranslationProps = translationProps
}

export const setGlobalLocale = (locale) => {
  globalLocale = locale
}

export const getLocale = () => {
  return globalLocale
}

export const getTranslation = () => {
  return {
    t: function(key:string): string {
      return globalTFunction ? globalTFunction(key) : key
    }
  }
}

export const useTranslation = () => {
  const t = initTranslationBrowser();
  return { t }
}

