import React from 'react';
import { typedMemo } from '../../helpers/typed-memo';
import { Button, Menu, MenuItem, MenuProps, withStyles } from '@material-ui/core';
import { AuthUserInfo } from '../../models/auth/auth.user.info';
import { menuButtonColor } from '../../theme/theme';
import { LoaderButton } from '../common/button/LoaderButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import { ExitToApp, LockOpen, Person } from '@material-ui/icons';
import Divider from '@material-ui/core/Divider';
import { initTranslationBrowser } from '../../translation/translation-browser';
import { useRouter } from 'next/router';
import { useTranslation } from '../../translation/translations';

const UserPopupMenu = withStyles({
  paper: {
    transform: 'translateY(-12px)!important',
    top: '12px',
  },
  list: {},
})(React.forwardRef((props: MenuProps, ref) => <Menu ref={ref} getContentAnchorEl={null} {...props} />));

const UserMenuItem = withStyles({
  gutters: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  root: {
    fontSize: '14px',
    textTransform: 'uppercase',
    color: menuButtonColor,
    fontWeight: 500,
  },
})(React.forwardRef((props: any, ref) => <MenuItem ref={ref} {...props} />));

export interface UserMenuProps {
  loading: boolean;
  userInfo?: AuthUserInfo;
  walletUser?: {
    address: string;
  };
  onLogin: () => void;
  onLogout: () => void;
  onPathSelected: (val: string) => void;
  isSidebar?: boolean;
  onOpenReferralProgram?: Function;
  handleDrawerClose?: Function;
}

function UserMenu(props: UserMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  initTranslationBrowser();

  const router = useRouter();

  const { t } = useTranslation();

  return !(props.userInfo || props.walletUser) ? (
    !props.isSidebar ? (
      <div className="menu menu-usermenu">
        <LoaderButton
          className={'login-button'}
          loading={props.loading}
          variant="contained"
          color="primary"
          onClick={props.onLogin}
        >
          {t('menu-login')}
        </LoaderButton>
      </div>
    ) : (
      <ListItem button={true} onClick={props.onLogin}>
        <ListItemIcon>
          <LockOpen />
        </ListItemIcon>
        <ListItemText primary={t('menu-login')} />
      </ListItem>
    )
  ) : !props.isSidebar ? (
    <>
      <div className="menu menu-usermenu">
        <Button className="user-menu-anchor" aria-controls="simple-menu" onClick={handleClick} aria-haspopup="true">
          <span className={'user-menu-image'}>
            {props.userInfo && (
              <img src={props.userInfo.picture} className="menu-icon" alt="" referrerPolicy="no-referrer" />
            )}
          </span>
          <span className={'user-menu-name'}>{props.userInfo?.name || props.walletUser?.address}</span>
        </Button>
      </div>
      <UserPopupMenu
        id="user-menu"
        disableScrollLock={true}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <UserMenuItem
          className="username-menu-item"
          onClick={() => {
            setAnchorEl(null);
          }}
        >
          <span>
            {props.userInfo && (
              <img src={props.userInfo.picture} className="menu-icon" alt="" referrerPolicy="no-referrer" />
            )}
            {props.userInfo?.name || props.walletUser?.address}
          </span>
        </UserMenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            props.onPathSelected('/profile');
          }}
        >
          {t('menu-profile')}
        </MenuItem>
        {/*<MenuItem onClick={() => props.onOpenReferralProgram && props.onOpenReferralProgram()}>{"Referral program"}</MenuItem>*/}
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            props.onLogout();
          }}
        >
          {t('menu-logout')}
        </MenuItem>
      </UserPopupMenu>
    </>
  ) : (
    <>
      <Divider />
      <ListItem className="username-menu-item">
        <ListItemIcon>
          {props.userInfo && (
            <img src={props.userInfo.picture} className="menu-icon" alt="" referrerPolicy="no-referrer" />
          )}
        </ListItemIcon>
        <ListItemText primary={props.userInfo?.name || props.walletUser?.address} />
      </ListItem>
      <ListItem
        button={true}
        onClick={() => {
          props.handleDrawerClose();
          props.onPathSelected('/profile');
        }}
      >
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        <ListItemText primary={t('menu-profile')} />
      </ListItem>
      {/*<ListItem button={true} onClick={() => props.onOpenReferralProgram && props.onOpenReferralProgram()}>
          <ListItemIcon><Share /></ListItemIcon>
          <ListItemText primary={"Referral program"} />
        </ListItem>*/}
      <ListItem
        button={true}
        onClick={() => {
          props.handleDrawerClose();
          props.onLogout();
        }}
      >
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary={t('menu-logout')} />
      </ListItem>
    </>
  );
}

export default typedMemo(UserMenu);
