import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

export function LoaderButton(props: { loading: boolean } & ButtonProps) {
  const loaderClassNames = `io-mat-loader-btn ${props.className}`;
  const {loading,...restProps} = props;
  return (
    <Button {...restProps} className={props.loading ? loaderClassNames : props.className} disabled={props.loading}>
      {props.children}
    </Button>
  );
}
